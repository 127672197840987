import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../components/Layout"
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import PostMeta from "../../components/PostMeta"

export default ({ data }) => {
  const { page } = data
  const {
    title,
    content,
    categories,
    databaseId,
    author,
    date,
    seo,
    slug,
    featuredImage
  } = page

  return (
    <Layout bodyClass={`post-template-default single single-post postid-${databaseId} single-format-standard wp-embed-responsive singular has-post-thumbnail has-single-pagination showing-comments footer-top-visible customize-support`}>
      <GatsbySeo 
        title={seo.title} description={seo.metaDesc} 
        canonical={`https://www.vetoveljet.com/${slug}/`}
      />
      <article className={`post-${databaseId} post type-post status-publish format-standard has-post-thumbnail hentry category-uncategorized`} id={`post-${databaseId}`}>
        <header className="entry-header has-text-align-center header-footer-group">
          {featuredImage !== null &&
            <img className="featuredImg" src={featuredImage.node.mediaItemUrl} alt={featuredImage.node.altText} width="1000"/>
          }
          <h1 className="entry-title" dangerouslySetInnerHTML={{ __html: title }} />
        </header>
        <div className="post-inner thin">
          <div className="post-meta">
            <PostMeta title={title} author={author} date={date} />
            <div className="entry-categories">
              <div className="entry-categories-inner">
                {categories.nodes.map((category, index) => (
                  <Link to={category.uri} key={index} rel="category tag">
                    {category.name}
                  </Link>
                ))}
              </div>
            </div>
          </div>
          <div className="entry-content" dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      </article>
    </Layout>
  )
}

export const query = graphql`
  query post($id: String!, $nextPage: String, $previousPage: String) {
    page: wpPost(id: { eq: $id }) {
      title
      ACFArtikkeliIntro {
        intro
      }
      content
      databaseId
      date(formatString: "DD.MM.YYYY")
      seo {
        metaDesc
        title
      }
      slug
      categories {
        nodes {
          name
          uri
          id
        }
      }
      featuredImage {
        node {
          id
          mediaItemUrl
          altText
        }
      }
    }
    nextPage: wpPost(id: { eq: $nextPage }) {
      title
      uri
    }
    previousPage: wpPost(id: { eq: $previousPage }) {
      title
      uri
    }
  }
`
